import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql, Link } from "gatsby";
import {
  FaClock,
  FaComments,
  FaHistory,
  FaSearch,
  FaHeartbeat,
  FaUsers
} from "react-icons/fa";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    kom1: file(relativePath: { eq: "Prosty-CRM-Komunikator-.png" }) {
      childImageSharp {
        fluid(maxWidth: 1340, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kom2: file(relativePath: { eq: "Prosty-CRM-Wiadomosci.png" }) {
      childImageSharp {
        fluid(maxWidth: 1340, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    kom3: file(relativePath: { eq: "Prosty-CRM-Wiadomosci1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1340, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Komunikator = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Komunikator w prostym systemie CRM"
      headline="Usprawnij przepływ informacji"
    >
      <SEO
        title="Komunikator w prostym CRM-ie - usprawnij komunikację"
        description="Komunikator w systemie CRM • Przekazuj ważne informacje pracownikom • Wypróbuj QuickCRM i zacznij sprzedawać więcej, pierwsze 30 dni za darmo!"
        keywords={["komunikator"]}
      />
      <div>
        <p>
          Prosty CRM pozwoli{" "}
          <strong>
            usprawnić wymianę informacji&nbsp;w&nbsp;Twoim zespole
          </strong>
          . Możesz wysyłać wiadomości <strong>do jednej lub wielu osób</strong>,
          a&nbsp;także <strong>załączać niezbędne pliki,</strong> np. oferty lub
          zdjęcia. Komunikator posiada <strong>wyszukiwarkę</strong>, dzięki
          której możesz szybko odnaleźć&nbsp;wszystkie wiadomości powiązane
          z&nbsp;daną osobą lub grupą. Nieważne jakie czynności w&nbsp;systemie
          właśnie wykonujesz. Nie musisz tracić cennego czasu na przełączanie
          się pomiędzy aplikacjami i&nbsp;przesyłanie wiadomości oraz plików
          w&nbsp;wielu miejscach.
        </p>
        <p>
          Wiadomości możesz wysyłać
          <strong> z&nbsp;różnych miejsc systemu</strong>. Jeśli chcesz{" "}
          <strong>przekazać pracownikowi ważną informację</strong> dotyczącą np.{" "}
          <Link to="/sprzedaz-w-crm/">szansy sprzedaży</Link>, możesz z&nbsp;jej
          poziomu&nbsp;wysłać wiadomość. W&nbsp;ten sposób wiadomość zostanie
          powiązana z&nbsp;szansą i&nbsp;będzie&nbsp;
          <strong>łatwo dostępna.</strong>
        </p>
      </div>
      <br />
      <br />
      <Lightbox images={[data.kom1, data.kom2, data.kom3]} 
      alts={["komunikator w crm, komunikator w systemie crm, wbudowany komunikator w crm"]}/>
      <h3
        style={{
          textAlign: "center"
        }}
      >
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaComments size="45px" />}
          content="Wygodną i sprawną komunikację w firmie"
        />
        <BenefitBlock
          icon={<FaHistory size="45px" />}
          content="Dostęp do historii wiadomości"
        />
        <BenefitBlock
          icon={<FaSearch size="45px" />}
          content="Wyszukiwanie wiadomości"
        />
      </BenefitBlockWrapper>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaHeartbeat size="45px" />}
          content="Oddzielenie komunikacji firmowej od prywatnej"
        />
        <BenefitBlock
          icon={<FaClock size="45px" />}
          content="Oszczędność czasu na przełączaniu się między innymi aplikacjami"
        />
        <BenefitBlock
          icon={<FaUsers size="45px" />}
          content="Możliwość czatu w grupie"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default Komunikator;
